<template>
  <teleport to="body">
    <div v-if="visible" class="image-preview-overlay" >
      <div class="image-preview-content" @click="closePreview">
        <img :src="imageSrc" :style="imageStyles" alt="Image Preview" class="preview-image" />
      </div>
      <div class="tool-bar">
        <ul>
          <li @click="zoomIn" class="tool-btn"><ZoomIn /></li>
          <li @click="zoomOut" class="tool-btn"><ZoomOut /></li>
          <li @click="rotateLeft" class="tool-btn"><RotateLeft /></li>
          <li @click="rotateRight" class="tool-btn"><RotateRight /></li>
        </ul>
      </div>
      <CloseSvg class="close-btn" @click="closePreview">×</CloseSvg>
    </div>
  </teleport>
</template>

<script setup>
const CloseSvg = defineAsyncComponent(() => import('@/assets/svgs/close.svg'));
const ZoomIn = defineAsyncComponent(() => import('@/assets/svgs/zoomIn.svg'));
const ZoomOut = defineAsyncComponent(() => import('@/assets/svgs/zoomOut.svg'));
const RotateLeft = defineAsyncComponent(() => import('@/assets/svgs/rotateLeft.svg'));
const RotateRight = defineAsyncComponent(() => import('@/assets/svgs/rotateRight.svg'));

const props = defineProps({
  imageSrc: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['close'])

const visible = ref(true)
const scale = ref(1)
const rotation = ref(0)

const imageStyles = computed(() => ({
  transform: `scale(${scale.value}) rotate(${rotation.value}deg)`
}))

const zoomIn = () => {
  scale.value += 0.1
}

const zoomOut = () => {
  scale.value -= 0.1
}

const rotateLeft = () => {
  rotation.value -= 90
}

const rotateRight = () => {
  rotation.value += 90
}
const closePreview = () => {
  visible.value = false
  emit('close') // Emit close event to parent for cleanup
}
</script>

<style scoped>
.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.image-preview-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  img {
    transition: all .2s;
    user-select: none;
  }
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
}
.tool-bar {
  position: absolute;
  bottom: 30px;
  ul {
    height: 44px;
    padding: 0 23px;
    background-color: var(--el-text-color-regular);
    border-color: #fff;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: 0 10px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 24px;
        height: 24px;
        fill: #FFFFFF;
      }
    }
  }
}
.close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  background: #606266;
  fill: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  width: 44px;
  height: 44px
}
</style>
